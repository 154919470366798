import React from "react";
import { Link, graphql, StaticQuery } from "gatsby";
import DefaultLayout from "../layouts/default";
import FluidImage from "../components/FluidImage.js";
import Breadcrumbs from "../components/Custom/Navigation/Breadcrumbs";

export default ({ location }) => {
	//  const dispatch = useDispatch();

	//   React.useEffect(() => {

	//   });

	return (
		<DefaultLayout location={location}>
			
            <Breadcrumbs location={location} />

            {/* on this page */}
			<div className="container is-fullwidth">
				<section className="section section-mini">
					<p className="is-normal">
						On this page:{" "}
						<a href="#1" className="">
							Cooperative
						</a>
						,{" "}
						<a href="#2" className="">
							Venture builder
						</a>
						,{" "}
						<a href="#3" className="">
							Nerdy details
						</a>
					</p>
				</section>
			</div>

            {/* cooperative */}
			<a id="1" className="scroll-anchor"></a>
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>
                                Is a cooperative something coming from the old ages, like CDs or Video Rental Stores?
							</h1>
                            <p>
								&mdash; If you also thought this, don’t mind. We did so, too. But when we got more involved with the structure of cooperatives, it turned out that it was exactly the type of homebase we had in mind. We were looking for an easy way to set up projects, onboard members and work together under one flag, without setting up huge and complex businesses every time.
							</p>
						</div>
					</div>

					<div className="columns is-tablet mt-25">
						<div className="column">
							<h4>
                                An academic description.
							</h4>
							<p className="is-normal">
                                Here’s how the International Co-operative Alliance describes cooperatives:
							</p>
							<p className="is-normal mt-25">
                                A cooperative (also known as co-operative, co-op, or coop) is &bdquo;an autonomous association of persons united voluntarily to meet their common economic, social, and cultural needs and aspirations through a jointly-owned enterprise&rdquo;. Cooperatives are democratically owned by their members, with each member having one vote in electing the board of directors.
							</p>
						</div>
						<div className="column">
							<h4>
                                Based on values.
							</h4>
							<p className="is-normal">
                                We put a set of values in place which found the base of us working together:
							</p>
                            <div className="tags are-medium mt-25">
                                <span className="tag">Trustworthiness</span> 
                                <span className="tag">Integrity</span> 
                                <span className="tag">Honesty</span> 
                                <span className="tag">Boldness</span> 
                                <span className="tag">Fairness</span> 
                                <span className="tag">Accountability</span> 
                                <span className="tag">Fun</span> 
                                <span className="tag">Result oriented</span> 
                                <span className="tag">Constant Improvement</span> 
                                <span className="tag">Diversity</span> 
                                <span className="tag">Innovation</span> 
                                <span className="tag">Quality</span> 
                                <span className="tag">Teamwork</span> 
                                <span className="tag">Partnership</span> 
                                <span className="tag">Factfullness</span> 
                                <span className="tag">Unselfishness</span> 
                                <span className="tag">Self-Respect</span> 
                            </div>
                            <nav className="breadcrumb breadcrumb-forward is-small is-letter-spacing is-uppercase mt-50">
								<ul>
									<li>
										<Link to={"/codeofconduct"}>
											Code of Conduct
											<span className="icon icon-arrow-right"></span>
										</Link>
									</li>
								</ul>
							</nav>
						</div>
					</div>

				</section>
			</div>

			{/* venture builder */}
            <a id="2" className="scroll-anchor"></a>
			<div className="container is-layout">
                <section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
                            <p className="is-normal">
                                Okay, so you know why we did choose a cooperative for Network Ventures. But:
                            </p>
                            <h1>
                                What the heck is a Venture Builder?
							</h1>

							<div className="mt-25">
								<FluidImage
									src="about/with_without.png"
									alt="With and without venture builder support"
								/>
							</div>

                            <p className="mt-25">
								&mdash; Let’s start off easy with a list of pros and cons for working with us, a network venture builder.
							</p>
							
						</div>
					</div>

					<div className="columns is-tablet mt-25">
						<div className="column">
							<h4>
                                Pros.
							</h4>
                            <ul className="nv-list is-normal">
                                <li>Cooperative of hands-on experts out of all fields of business</li>
                                <li>Not distracted by fundraising</li>
                                <li>Increased speed to market</li>
                                <li>Office space / functional support</li>
                                <li>Network of enablers / unlockers</li>
                                <li>Deep collaboration with group companies</li>
                                <li>Get paid a salary (Yes, our future plan is to pay you for working on your idea)</li>
                                <li>Avoid lonely toil</li>
                            </ul>
						</div>
						<div className="column">
							<h4>
                                Cons.
							</h4>
							<ul className="nv-list is-normal">
                                <li>Yield control (No worries, we will never ever do something that will put you or the project at risk; for more information on this check our <Link to={"/codeofconduct"}>Code of Conduct</Link>)</li>
                                <li>Give up more of equity in the beginning</li>
                            </ul>
						</div>
					</div>

					<div className="columns is-tablet is-centered mt-25">
						<div className="column is-8">
                            <h4>
								Now it gets a little technical but try to stay with us, there will be barbecue at the end you remember?
							</h4>
							<p className="is-normal mt-25">
								The goal of us as a venture builder is to provide the initial startup team with hands-on help and strategic direction along with capital to help your startup reach the open market.
							</p>
							<p className="is-normal mt-25">
								There is a huge difference from the approach taken by venture capitalists, because we as a venture builder are involved in the direct daily operations of your new business. You can see us as one part of your startup team with all our members and their network and expertise within easy reach. So it’s like: Buy one get 100.
							</p>
							<p className="is-normal mt-25">
								There are also some notable differences between a venture builder and an incubator.
								In incubator programs startups often show up for a limited amount of time (normally some
								weeks) and get some initial seed money in exchange for equity in the company.
							</p>
							<p className="mt-25">
								We as a venture builder focus on building a company together with our members.
							</p>
							<p className="is-normal mt-25">
								Therefore there is no limited amount of time we work with you on a project. As a member network we also have a huge amount of people on the bench who can join in the game at any time. So in contrast to an incubator, who brings in multiple startups at a time and has a limited amount of workforce at hand, we put together a dedicated team of members for every project and make sure everybody has the ressources to work with you on your idea
							</p>
							<p className="is-normal mt-25">
								As we start working with founders and project teams in a very early stage of development, sometimes it’s only a spark of an idea, we take part in one of the hardest parts of the company lifecycle. We are building out a new organization instead of growing an already established company, which is where venture building and startup accelerators diverge.
							</p>
							<h4 className="mt-50">
								Okay. So now you know some of the differences between a Venture Capitalist, an Incubator and a Venture Builder. But what exactly does this mean for your idea?</h4>
							<p className="is-normal mt-25">
								As a Venture Builder we don’t get paid in money, we get paid in shares. This means to get this rocket off the ground we need a piece of the cake and to be honest it’s not a small piece. But don’t be afraid. There is enough cake for everybody.
							</p>
							<p className="is-normal mt-25">
								In contrast to the other business models we don’t give you money and some tips for 15% equity and send you on your way. We wanna be part of your new company and grow it with you to become the next big thing. That is why we only accept projects in our cooperative that we believe in ourselves. Why? Because your success is our success.
							</p>
							<h4 className="mt-50">
								Enough prep talk.
							</h4>
							<p className="is-normal">
								If you now think &bdquo;great I wanna join and start working&rdquo; let’s go and <Link to={"/contact"}>get in touch</Link>!
							</p>
							{/* 
                            <nav className="breadcrumb breadcrumb-forward is-small is-letter-spacing is-uppercase mt-50">
								<ul>
									<li>
										<a href="javascript:onclick()">
											Dive in even deeper ...
										</a>
									</li>
								</ul>
							</nav> */}
						</div>
					</div>

				</section>
			</div>

			{/* nerdy details */}
			<a id="3" className="scroll-anchor"></a>
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>
								Now for the nerdy details.
							</h1>
							<p className="is-normal mt-25">
								Like mentioned in the cons list above, we need to have the majority of the equity in the beginning. The amount of shares we need to get your project going depends on the parts of the business setup we will provide (e.g. sales, technical support, sourcing, business development, leadership, fundraising, product development etc. etc.).
							</p>
							<p className="mt-25">Stop! Don’t close this website yet. Let us explain why this is good for you as an entrepreneur.</p>
							<h4 className="mt-50">
								What does it mean to start a new venture?
							</h4>
						</div>
					</div>

					<div className="columns is-tablet mt-25">
						<div className="column">
							<FluidImage
								src="about/stack_solo.png"
								alt="Without venture builder support"
							/>
						</div>
						<div className="column">
							<h4>Without venture builder support.</h4>
							<p className="is-normal">
								As a solopreneur or startup team you need to take care of all the tasks by yourselfs.
                            </p>
						</div>
					</div>

					<div className="columns is-tablet mt-25">
						<div className="column">
							<FluidImage
								src="about/stack_venture.png"
								alt="With venture builder support"
							/>
						</div>
						<div className="column">
							<h4>With venture builder support.</h4>
							<p className="is-normal">
								With us as a venture builder by your side, you can focus on the things you really like to do and you are good at, for example developing the product or making sales. All other things are done by our experts. We find and recruit the matching partners to get your business going. Sales, Funding, Technical support, Marketing, Social media, Sourcing, Admin, Legal, etc. etc. Let us take care of this part while you put your head into your idea. That sounds great, doesn't it?
							</p>
						</div>
					</div>

					<div className="columns is-tablet is-centered mt-25">
						<div className="column is-8">
							<h4>
								What you might say now.
							</h4>
							<p className="is-normal">
								&bdquo;Okay that sounds good, but at the end I have so much less money than I would have without your help.&rdquo; That's a legitimate objection but let us show you a small calculation example. The formula is quite easy:
							</p>
							<p className="mt-25">
								return = share x size x likelihood
							</p>
							<p className="is-normal mt-25">
								There are different key figures in this formula to evaluate the return.
							</p>

							<h4 className="mt-25">
								Share
							</h4>
							<p className="is-normal">
								If you bootstrap your business all alone and bring in different experts (you pay with shares), raise some money from friends and family (giving away some more shares) and finally getting an investor in (taking shares also) you might end up keeping around 20% of your equity. Hey that’s almost the same amount you have at the end if you partner with us, but let’s keep going.
							</p>
							<p className="is-normal mt-25">
								So <span className="is-uppercase">share</span> = 20%
							</p>

							<h4 className="mt-25">
								Size
							</h4>
							<p className="is-normal">
								Let’s assume the exit value is similar no matter if you partner with a VC or with us, although our support and network could lead to a higer exit value.
							</p>
							<p className="is-normal mt-25">
								So a 5 Million investment brings us to an estimated exit of 25 Million.
							</p>
							<p className="is-normal mt-25">
								Let’s remember <span className="is-uppercase">size</span> = 25. We need this for the calculation at the end.
							</p>

							<h4 className="mt-25">
								Likelihood
							</h4>
							<p className="is-normal">
								The average venture returns are poor. Venture companies return no better than the market and only the best startups get venture funded at all.
							</p>
							<p className="is-normal mt-25">
								Fewer than a quarter of VC-backed firms return the VC investment. Fewer than 10% create 3x return. So 8% is a good value for our calculation.
							</p>
							<p className="is-normal mt-25">
								80% of companies coming from Venture Builders have exited with a positive return or are still trading. Even 60% are still far above the average compared to VCs.
							</p>
							<p className="is-normal mt-25">
								Let’s keep in mind that <span className="is-uppercase">likelihood</span> = 60%
							</p>

							<h4 className="mt-25">
								Time
							</h4>
							<p className="is-normal">
								This is one of the most important key figures of all. Time. We help you get your idea to market quicker than you could do alone. With our network of members and partners we not only have knowhow and hands-on workforce, we have direct contact into the segments of the market your product fits. We know exactly to whom we need to talk and can use our huge network as a multiplier for getting your product or service on the desks of the right people.
							</p>
							<p className="is-normal mt-25">
								So <span className="is-uppercase">time</span> = 3 years until a potential exit instead of an average of 5 years with a VC
							</p>

							<h4 className="mt-50">
								Now that we have all the numbers in place let’s see what comes out at the end.
							</h4>
							<p className="is-normal">
								Here’s once again the formula:
							</p>
							<p className="mt-25">
								return = share x size x likelihood
							</p>

							<p className="is-normal mt-25">
								So here we go:
							</p>
						</div>
					</div>

					<div className="columns is-tablet mt-25">
						<div className="column">
							<h4>Return after 5 years with the VC route&nbsp;=<br/>
							20% x 25 Million Euro x 6% = 0,4 Million Euro</h4>
						</div>
						<div className="column">
							<h4 className="has-text-primary">Return after 3 years with Network Ventures&nbsp;=<br/>
							10% x 25 Millionen x 60% = 1,5 Million Euro</h4>
						</div>
					</div>

					<div className="columns is-tablet is-centered mt-25">
						<div className="column is-8">
							<p className="is-normal">
								So you see you still can get rich with giving up some more shares. Because with us as your, let’s stick to rocket language, afterburner which accelerates the speed and likeliness of your project going to market pulverizes the smaller amount of shares.
							</p>
							<h4 className="mt-50">One last thing.</h4>
							<p className="is-normal">
								If you think you already have a great team and only need some services from Network Ventures members or us as a cooperative, we are interested in helping you with this as well.
							</p>

						</div>
					</div>

				</section>
			</div>

		</DefaultLayout>
	);
};
